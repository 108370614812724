.navbarTextStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
}

.navbarHeadingStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
}

a:hover {
  color: #4ac308;
  text-decoration: none;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media screen and (min-width: 767px) {
  .urbinContainer {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    margin-top: 0px;
    height: 85vh;
    /* border-width: thin;
    border-color: red;
    border-style: solid; */
  }
}

@media screen and (min-width: 300px) { /*if min-width: 767px apply padding */
  .urbinContainer {
    
  }
}

.legend {
  height: 100px;
  width: 150px;
  background-color: #fff;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  margin: 10px 10px;
  margin-top: 20px;
}

.binContainer {
  border-style: solid;
  border-color: #cecece;
  border-width: 1.45px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.dashboardContainer {
  border-radius: 5px;
  width: 20%;
  height: 110px;
  padding: 10px;
  box-shadow: 5px 10px 32px #c3c3c3;
  text-align: center;
}

.fillPercentContainerPending {
  border-style: solid;
  height: 30px;
  font-family: 'Source Sans Pro';
  color: #F5A623;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  font-size: 16px;
  border-color: #F5A623;
}

.fillPercentContainerCompleted {
  border-style: solid;
  height: 30px;
  font-family: 'Source Sans Pro';
  color: #41B509;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  font-size: 16px;
  border-color: #41B509;
}

.fillPercentContainerScheduled {
  border-style: solid;
  height: 30px;
  font-family: 'Source Sans Pro';
  color: #4A90E2;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  font-size: 16px;
  border-color: #4A90E2;
}

h5 {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.5px;
  color: #46B40E;
}

h4 {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.5px;
  color: #fff;
}


h2 {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  color: #000;
}

.urbinDetailsHeading {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 16px;
  color: #000;
  
}

.urbinDetailsText {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 18px;
  color: #575757;
  width: 33.33333333%;
  text-align: center;
}

.orderHeaderStyle {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 20px;
  color: #000;
  line-height: 2;
  margin-top: 5px;
}

.box {
  border-style: solid;
  border-width: 1.45px;
  border-color: #ECEFF1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
  height: 80px;
  text-align: center;
}

.tableContent {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
}

.divCenter {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-color: #dfdfdf;
  border-style: solid;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.divCenter1 {
  width: 100%;
  text-align: center;
  border-width: 1px;
  border-color: #dfdfdf;
  border-style: solid;
}

.progressBarStyle {
  height: 260px;
  width: 150px;
  margin: 0 auto;
  padding-top: 30px;
}

.detailsBarStyle {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  padding-top: 30px;
}

.kwDetailsBarStyle {
  height: 600px;
  width: 800px;
  margin: 0 auto;
  box-shadow: 8px 10px 100px #c3c3c3;
  border-radius: 10px;
}

.test {
  min-height: 100px;
  margin-bottom: 10px;
  box-shadow: 8px 10px 20px #c3c3c3;
  box-sizing: border-box;
}

.button-style {
  color: #fff;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  letter-spacing: 0.6px;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #5FBE36;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;
  display: inline-block;
  border-style: solid; 
  border-width: 2px;
  border-color: #5FBE36;
}

.button-style:hover {
  color: #5FBE36;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  letter-spacing: 0.6px;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  margin-left: 5px;
  display: inline-block;
  border-style: solid; 
  border-width: 2px;
  border-color: #5FBE36;
}

.input-style {
  border-style: solid; 
  width: 100px;
  border-width: 2px;
  border-color: #5FBE36;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  margin: 0px 5px;
  padding: 5px 5px;
  text-transform: uppercase;
}

.box-container {
  border-radius: 5px;
  padding: 10px;
  box-shadow: 10px 0.5px 20px #e3e3e3;
  margin-bottom: 20px;
}

.profile-container {
  border-radius: 5px;
  padding: 10px;
  box-shadow: 10px 0.5px 20px #e3e3e3;
  margin-bottom: 10px;
}

.urbinDetails__container {
  margin-left: 10px;
  margin-right: 10px;
}

.urbinDetails__profile {
  border-bottom-width: medium;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: #6D6D6D;
  border-style: solid;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  letter-spacing: 0.7px;
  font-size: 20px;
}

.urbinDetails__label {
  color: #6D6D6D;
  font-weight: 10px;
  font-family: 'Source Sans Pro';
  line-height: 2;
}

.urbinDetails__values {
  color: #434343;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  letter-spacing: 0.5px;
  line-height: 2;
}

.urbinDetails__label-box {
  color: #6D6D6D;
  font-weight: 10px;
  font-family: 'Source Sans Pro';
  line-height: 2;
  letter-spacing: 1px;
}

.urbinDetails__values-box {
  color: #434343;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  letter-spacing: 0.5px;
  line-height: 2;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.col__height {
  height: 85vh;
  border-width: thin;
  border-color: red;
  border-style: solid;
}

.h-45 {
  height: 45px;
}

.profile__heading-binName {
  font-size: 20px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
}

.fill-percentage-container {
  position: relative;
}


.fill-percentage-green {
  padding: 3px 10px;
  background-color: #40B506;
  font-family: 'Source Sans Pro';
  font-size: 14;
  color: #fff;
  letter-spacing: 0.6px;
  margin-top: 10px;
}

.fill-percentage-orange {
  padding: 3px 10px;
  background-color: #F5A623;
  font-family: 'Source Sans Pro';
  font-size: 14;
  color: #fff;
  letter-spacing: 0.6px;
  margin-top: 10px;
}

.fill-percentage-red {
  padding: 3px 10px;
  background-color: #CF021A;
  font-family: 'Source Sans Pro';
  font-size: 14;
  color: #fff;
  letter-spacing: 0.6px;
  margin-top: 10px;
}

.fill-percentage-gray {
  padding: 3px 10px;
  background-color: #9B9B9B;
  font-family: 'Source Sans Pro';
  font-size: 14;
  color: #fff;
  letter-spacing: 0.6px;
  margin-top: 10px;
}

.spinnerContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.urbinListViewButton {
  position: absolute;
  z-index: 100;
}

.hidden { display:none; }





